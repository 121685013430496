import { extendTheme } from '@chakra-ui/react';

const theme = {
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
      },
      '#gatsby-focus-wrapper': {
        minH: '100vh',
        display: 'flex',
        flexDir: 'column',
        position: 'relative',
      },
    },
  },
  fonts: {
    heading: `'Roboto Slab Variable', sans-serif`,
    body: `'Roboto Slab Variable', sans-serif`,
  },
  colors: {
    purple: {
      50: '#e4ecff',
      100: '#b5c7ff',
      200: '#85a2fa',
      300: '#557df7',
      400: '#2858f3',
      500: '#133eda',
      600: '#0b30aa',
      700: '#05237a', // main
      800: '#00154b',
      900: '#00071d',
    },
  },
  shadows: {
    outline: 'none',
  },
};

export default extendTheme(theme);
